import { cachedFetch } from './fetchUtils';
import { API_DOMAIN, API_PROTOCOL } from './regions';
import { useEffect, useState } from 'react';

export async function fetchIsOnForAll(featureFlag: string): Promise<boolean> {
  return cachedFetch(
    `${API_PROTOCOL}://${API_DOMAIN}/feature-flags/flags/${featureFlag}/onforall`,
  );
}

/**
 * Hook to determine if a flag is all for on
 * @param featureFlag
 * @returns boolean or undefined if not determined yet
 */
export function useIsOnForAll(featureFlag: string): boolean | undefined {
  const [isOn, setIsOn] = useState<boolean>();
  useEffect(() => {
    fetchIsOnForAll(featureFlag).then((bool: boolean) => {
      setIsOn(bool);
    });
  }, [featureFlag]);
  return isOn;
}
