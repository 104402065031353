/* eslint-disable @typescript-eslint/ban-ts-comment */
import { graphql, navigate } from 'gatsby';
import { classes } from '../../utils';
import styles from './product-page.module.scss';
import React, { useEffect, useState } from 'react';
import {
  EngravingInfo,
  getGenderFromSelection,
  getSizingGuideFromSelection,
  Modal,
  Optional,
  ProductDetails,
  ProductItem,
  PulsingLogo,
  SizingGuide,
} from '@whoop/web-components';
import ProductHighlights from '../../components/ProductHighlights';
import { SHOP_CURRENCY_CODE, PREFER_METRIC } from '../../utils/regions';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import {
  klaviyoTrackViewedChildProduct,
  trackOpenedSizeGuide,
  trackWhoopAnalyticsEvent,
} from '../../utils/analytics';
import BuyBox from '../../components/BuyBox';
import {
  useProductNode,
  useStaticProductMap,
  useStaticRootHandle,
} from '../../hooks/staticProductHooks';
import Seo from '../../components/Seo';
import { useUser } from '../../redux/hooks';
import { useProductUrlState } from '../../hooks/urlSelectionHooks';
import {
  hasVaryingPatterns,
  isProductItemExclusive,
  ProductItemKey,
  useProductAccordions,
} from '../../utils/productUtils';
import { usePurchasableItem } from '../../utils/purchasableUtils';
import { useShopifyProductData } from '../../utils/shopifyProductUtils';
import { useDiscountMessage, usePriceTag } from '../../utils/priceUtils';
import JoinFlowCta from '../../components/JoinFlowCta/JoinFlowCta';
import { ShopifyProduct } from '../../types/generated';
import OkendoReviewsWidget from '../../components/OkendoReviewsWidget';
import { parseShopifyId } from '../../utils/shopify';
import OkendoReviewStarsWidget from '../../components/OkendoReviewsWidget/OkendoReviewStarsWidget';
import { useIsOnForAll } from '../../utils/feature-flags';
import {
  PDPRedesignVariant,
  ProductCardRedesignVariant,
  usePdpRedesign,
  useProductCardRedesign,
} from '../../hooks/usePdpRedesign';
import { is3for2Sale } from '../../utils/products';
/**
 * Redirects to root handle if it exists
 */
function useRedirectToRootProduct(handle: string) {
  const root = useStaticRootHandle(handle);
  // get the sku from the original handle
  const staticNodeMap = useStaticProductMap();
  const productItems = staticNodeMap[handle]?.items;
  let sku: Optional<string>;
  if (productItems && productItems?.length > 0) {
    sku = productItems[0]?.sku;
  }
  useEffect(() => {
    if (root && handle !== root && !!sku && !sku.includes('PCK')) {
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.set('sku', sku);
      navigate(`/products/${root}/?${searchParams.toString()}`, {
        replace: true,
      });
    } else if (root && handle !== root && window) {
      navigate(`/products/${root}/?${window.location.search}`, {
        replace: true,
      });
    }
  }, [root, staticNodeMap]);
}

type ProductPageProps = {
  pageContext: {
    shopifyProduct: ShopifyProduct;
    handle: string;
  };
};

export default function ProductPage({
  pageContext: { shopifyProduct, handle },
}: ProductPageProps): JSX.Element {
  useRedirectToRootProduct(handle);
  const { t } = useTranslation('product');
  const node = useProductNode(handle);
  const [value, setValue] = useProductUrlState(node);
  const accordions = useProductAccordions(value, node);
  const user = useUser();
  const gender = getGenderFromSelection(value!, node);
  const sizingGuide = getSizingGuideFromSelection(value!, node);
  const { variant, tags, earlyAccess } = useShopifyProductData(value?.sku);
  const discountMessage = useDiscountMessage(variant, tags);
  const [engraving, setEngraving] = useState<EngravingInfo>();
  const purchasable = usePurchasableItem(value, node, engraving);
  const priceTag = usePriceTag(tags, variant, purchasable);
  const isExclusive = isProductItemExclusive(
    purchasable,
    ProductItemKey.pro_exclusive,
  );
  const emailMeWhenEligible = isProductItemExclusive(
    purchasable,
    ProductItemKey.email_when_oos,
  );
  const [showSizingModal, setShowSizingModal] = useState(false);
  const showReviews = useIsOnForAll('storefront-show-reviews');
  const is3for2SaleOnForAll = useIsOnForAll('storefront-3for2-sale');

  const onSizingGuideClick = () => {
    setShowSizingModal(true);
    trackOpenedSizeGuide(variant);
  };
  const pdpRedesignFeature = usePdpRedesign();
  const productCardRedesignFeature = useProductCardRedesign();

  const onAnalyticsEvent = (eventName: string, { item, ...context }: any) => {
    if (item) {
      const productItem = item as ProductItem;
      trackWhoopAnalyticsEvent(eventName, {
        ...context,
        is_sale: productItem.on_sale,
        is_new: productItem.new,
        is_oos: (productItem.quantity || 0) <= 0,
        is_exclusive: productItem.pro_exclusive,
      });
    } else {
      trackWhoopAnalyticsEvent(eventName, context);
    }
  };
  const disclaimer =
    user?.isEmployee && value?.employee_discount === false
      ? t('employeeBlackout')
      : undefined;

  const [analyticsDidFire, setAnalyticsDidFire] = useState(false);
  useEffect(() => {
    if (analyticsDidFire) {
      return;
    }
    if (priceTag.price === '--') {
      return;
    }
    // Klaviyo requires specific Shopify info
    // If the variant does not have media configured, try to use the product's media instead
    const media =
      value?.media?.length && value.media.length > 0
        ? value.media
        : node?.product_info?.media;
    klaviyoTrackViewedChildProduct(shopifyProduct, priceTag, media?.[0]?.url);
    setAnalyticsDidFire(true);
  }, [value]);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const productId: string | null | undefined = parseShopifyId(
    shopifyProduct?.shopifyId,
  );
  return (
    <div>
      <Seo
        title={node?.product_info?.title}
        description={node?.product_info?.description || ''}
        keywords={[node?.product_info?.title || '', 'WHOOP']}
        imageSrc={node?.product_info?.featured_media?.url}
        type='product'
        meta={[
          {
            name: 'product:price:amount',
            content: variant?.price?.toString() || '',
          },
          {
            name: 'product:price:currency',
            content: SHOP_CURRENCY_CODE,
          },
        ]}
      />
      <section className={classes(styles.productPage, 'whoop-ui')}>
        {node && (
          <ProductDetails
            title={node?.product_info.title || ''}
            price={priceTag.price}
            originalPrice={priceTag.originalPrice}
            isProDiscount={priceTag.isProDiscount}
            node={node}
            value={value}
            onChange={(item: ProductItem, engraving?: EngravingInfo) => {
              setValue(item);
              setEngraving(engraving);
            }}
            specialSale={is3for2SaleOnForAll ? is3for2Sale(tags) : false}
            onSizeGuideClick={onSizingGuideClick}
            onAnalyticsEvent={onAnalyticsEvent}
            className={classes(
              pdpRedesignFeature === PDPRedesignVariant.no_sticky_pdp
                ? styles.productDetailsRedesign
                : styles.productDetails,
              productCardRedesignFeature ===
                ProductCardRedesignVariant.no_large_product_card
                ? styles.productCardRedesign
                : '',
            )}
            accordions={accordions}
            data-test-id='product-info'
            noImageScroll={node?.product_info?.handle === 'battery-pack'}
            subTitle={discountMessage || disclaimer}
            footer={
              // don't include CTA for membership product
              node.product_info.handle ===
              '12-month-whoop-membership-with-strap' ? undefined : (
                <JoinFlowCta
                  joinFlowCareText={node.product_info.handle.includes(
                    'replacement',
                  )}
                />
              )
            }
            showEngravingError={true}
            imageMessage={
              hasVaryingPatterns(tags) ? t('varyingPattern') : undefined
            }
            reviewStars={
              showReviews && <OkendoReviewStarsWidget productId={productId} />
            }
          >
            <BuyBox
              purchasable={purchasable}
              showEmailMeWhen={emailMeWhenEligible}
              node={node}
            />
            <ProductHighlights
              tags={tags || []}
              userStrap={user?.strap}
              userAvatar={user?.avatarUrl}
              isExclusive={isExclusive}
              earlyAccess={earlyAccess}
            />
          </ProductDetails>
        )}
        {!node && (
          <div className='loading-placeholder'>
            <PulsingLogo color='dark' size='large' />
          </div>
        )}
      </section>
      {sizingGuide && (
        <Modal show={showSizingModal} onClose={setShowSizingModal}>
          <SizingGuide
            className={styles.sizingGuide}
            gender={gender?.toUpperCase() || t('unisex')}
            name={node?.product_info?.title}
            type={sizingGuide}
            defaultToCm={PREFER_METRIC}
          />
        </Modal>
      )}
      {showReviews && <OkendoReviewsWidget productId={productId} />}
    </div>
  );
}

export const translationsQuery = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
