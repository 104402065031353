/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-unsafe-call */

import React, { useEffect, useMemo, useState } from 'react';
import {
  buildWywCompositeSku,
  NumberInput,
  PulsingLogo,
} from '@whoop/web-components';
import { WHOOP_YOUR_WAY_CUSTOMIZATION_FEE } from '../../utils/regions';
import {
  useBaseProDiscount,
  useHasProBenefits,
  useShowAsFree,
} from '../../redux/hooks';
import {
  klaviyoTrackViewedProductPage,
  trackWhoopAnalyticsEvent,
  withTracking,
} from '../../utils/analytics';
import styles from './whoop-your-way-page.module.scss';
import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { addItemToCart } from '../../redux/reducers/cartReducer';
import { showGlobalError, toggleCart } from '../../redux/reducers/viewReducer';
import { useReduxDispatch } from '../../redux/create-store';
import { WhoopYourWaySelection } from '../../utils/product-service';
import { classes } from '../../utils';
import { useQueryParam } from '../../utils/queryParamUtils';
import { Optional } from '@whoop/web-components/dist/types';
import { useWhoopYourWayConfigurations } from '../../utils/whoopYourWayUtils';
import OkendoReviewsWidget from '../../components/OkendoReviewsWidget';
import { parseShopifyId } from '../../utils/shopify';
import OkendoReviewStarsWidget from '../../components/OkendoReviewsWidget/OkendoReviewStarsWidget';
import {
  getDiscountFromTags,
  useDiscountMessage,
} from '../../utils/priceUtils';
import { useIsOnForAll } from '../../utils/feature-flags';
import Seo from '../../components/Seo';
import {
  Button,
  ButtonVariants,
  WhoopYourWay,
} from '../../libs/web-components';

type WhoopYourWaySelectionFilter = (
  selection: Optional<WhoopYourWaySelection>,
) => boolean;

export interface WhoopYourWayProps {
  data: {
    whoopProduct: {
      description: string;
      title: string;
    };
    shopifyProduct: {
      title: string;
      description: string;
      descriptionHtml: string;
      variants: Array<{
        shopifyId: string;
        price: string;
      }>;
      priceRangeV2: {
        minVariantPrice: {
          amount: string;
          currencyCode: string;
        };
        maxVariantPrice: {
          amount: string;
          currencyCode: string;
        };
      };
      tags: Array<string>;
      shopifyId: string;
    };
  };
}

export default function WhoopYourWayPage({ data }: WhoopYourWayProps) {
  const wywConfigurations = useWhoopYourWayConfigurations();
  const [wywSelection, setWywSelection] = useState<WhoopYourWaySelection>();
  // checks if wywSelection and all its direct children are non null
  const isValidSelection =
    !!wywSelection &&
    !Object.values(wywSelection).some((value) => value === undefined);
  const [quantity, setQuantity] = useState<number>(1);
  const isWhoopPro = useHasProBenefits();
  const dispatch = useReduxDispatch();
  const { t } = useTranslation(['whoopYourWay', 'product', 'collection']);
  const { variants, descriptionHtml } = data.shopifyProduct;
  const { description } = data.whoopProduct;
  const totalPrice: number =
    ((wywSelection?.band?.price || 0) +
      (wywSelection?.clasp?.price || 0) +
      (wywSelection?.hook?.price || 0) +
      (wywSelection?.slider?.price || 0)) /
    100;
  const showAsFree = useShowAsFree(data.shopifyProduct?.tags, totalPrice);
  const { discount, proDiscount } = getDiscountFromTags(
    data.shopifyProduct?.tags,
  );
  const baseProDiscount = useBaseProDiscount();
  const discountMessage = useDiscountMessage(
    undefined,
    data.shopifyProduct?.tags,
  );
  const showReviews = useIsOnForAll('storefront-show-reviews');

  const addToCart = () => {
    if (!isValidSelection) {
      return;
    }
    const compositeSku: string = buildWywCompositeSku(wywSelection);
    const lines = [
      { key: 'SKU', value: compositeSku },
      {
        key: 'Band',
        value: `${wywSelection?.band?.band_type_text} ${wywSelection?.band?.color_text}`,
      },
      { key: 'Clasp', value: `${wywSelection?.clasp?.color_text}` },
      { key: 'Hook', value: `${wywSelection?.hook?.color_text}` },
      { key: 'Slider', value: `${wywSelection?.slider?.color_text}` },
    ];

    const selectedVariant = variants.find((variant) => {
      return parseFloat(variant.price) === totalPrice;
    });

    if (selectedVariant) {
      dispatch(
        addItemToCart({
          // @ts-ignore
          variantId: selectedVariant.shopifyId,
          quantity: quantity,
          customAttributes: lines,
        }),
      );
      trackWhoopAnalyticsEvent('Add WYW to cart', {
        sku: compositeSku,
        quantity,
        material: wywSelection?.band?.band_type_group,
        band: wywSelection?.band?.color_group,
        clasp: wywSelection?.clasp?.color_group,
        hook: wywSelection?.hook?.color_group,
        slider: wywSelection?.slider?.color_group,
      });
      return dispatch(toggleCart());
    } else {
      dispatch(
        showGlobalError({
          message:
            'Could not add that WHOOP Your Way combination, please try again later',
        }),
      );
      throw new Error(
        `WhoopYourWay: Could not add variant for price ${totalPrice}`,
      );
    }
  };

  const getFreePrice = (tags: Array<string>) => {
    const freePrice = tags
      ?.filter((tag) => tag?.startsWith('__pro:free-price:'))
      ?.map((tag) => parseInt(tag?.split(':')[2] || '0'))
      ?.reduce((max, n) => Math.max(max, n), 0);
    return freePrice;
  };

  const freeFloorPrice = getFreePrice(data.shopifyProduct?.tags) * 100;

  const preselectFreeEligible: WhoopYourWaySelectionFilter = (wywSelection) => {
    if (!wywSelection) {
      return false;
    }
    const bandComboPrice =
      wywSelection.band.price +
      wywSelection.slider.price +
      wywSelection.hook.price +
      wywSelection.clasp.price;
    return bandComboPrice <= freeFloorPrice;
  };

  const preselectDefault: WhoopYourWaySelectionFilter = (wywSelection) => {
    return !!wywSelection;
  };

  const [preSelect] = useQueryParam('preselect', '');
  const preSelectFilter = useMemo(() => {
    if (preSelect) {
      switch (preSelect) {
        case 'free-item':
          return preselectFreeEligible;
        default:
          return preselectDefault;
      }
    }
  }, [preSelect]);

  useEffect(() => {
    // Klaviyo requires specific Shopify info
    klaviyoTrackViewedProductPage(data?.shopifyProduct);
  }, []);

  return (
    <div>
      <Seo title={t('collections:wyw')} keywords={['WHOOP']} type='product' />
      <section className={classes(styles.wywPage, 'whoop-ui')}>
        {wywConfigurations && (
          <WhoopYourWay
            className={styles.wyw}
            customizationFee={WHOOP_YOUR_WAY_CUSTOMIZATION_FEE}
            configurations={wywConfigurations}
            hasProBenefits={isWhoopPro}
            showAsFree={showAsFree}
            value={wywSelection}
            onChange={setWywSelection}
            onAnalyticsEvent={withTracking}
            data-test-id='product-info'
            preSelectFilter={preSelectFilter}
            discountPercent={discount}
            whoopProDiscountPercent={
              isWhoopPro ? Math.max(proDiscount, baseProDiscount) : undefined
            }
            subTitle={discountMessage}
            // @ts-ignore
            reviewStars={
              showReviews && (
                <OkendoReviewStarsWidget
                  productId={parseShopifyId(data?.shopifyProduct?.shopifyId)}
                />
              )
            }
          >
            <div className={styles.wywBuyBox}>
              <NumberInput
                min={1}
                value={quantity}
                onChange={withTracking((value: number) => {
                  return {
                    eventName: 'PDP Quantity Change',
                    context: {
                      new_quantity: value,
                    },
                  };
                }, setQuantity)}
              />
              <Button
                className={styles.wywBtn}
                label={t('addToCart')}
                variant={ButtonVariants.PRIMARY}
                disabled={!isValidSelection || !quantity}
                onClick={addToCart}
                data-test-id='add-to-cart'
              />
              <section
                className={styles.description}
                aria-label={t('aria.productDescription')}
                dangerouslySetInnerHTML={{
                  __html: description || descriptionHtml,
                }}
              />
            </div>
          </WhoopYourWay>
        )}
        {!wywConfigurations && (
          <div className='loading-placeholder'>
            <PulsingLogo color='dark' size='large' />
          </div>
        )}
      </section>
      {showReviews && wywConfigurations && (
        <OkendoReviewsWidget
          productId={parseShopifyId(data?.shopifyProduct?.shopifyId)}
        />
      )}
    </div>
  );
}

export const query = graphql`
  query ($handle: String!, $language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    shopifyProduct(handle: { eq: $handle }) {
      title
      descriptionHtml
      description
      variants {
        shopifyId
        price
      }
      tags
      shopifyId
      priceRangeV2 {
        minVariantPrice {
          currencyCode
          amount
        }
        maxVariantPrice {
          currencyCode
          amount
        }
      }
    }
    whoopProduct(handle: { eq: "whoop-your-way-kit" }) {
      title
      description
    }
  }
`;
