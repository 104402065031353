/* eslint-disable camelcase */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/restrict-template-expressions */

import { API_DOMAIN, API_PROTOCOL, SHOP_CURRENCY_CODE } from './regions';
import { ProductNode } from '@whoop/web-components';
import { useEffect, useState } from 'react';
import { WhoopPage } from '../types';
import { Optional } from '@whoop/web-components/dist/types';
import { singuralizeHook } from './index';
import { cachedFetch } from './fetchUtils';

export interface WhoopYourWayConfigurations {
  base: WhoopYourWayBase;
  band: WhoopYourWayComponent;
  clasp: WhoopYourWayComponent;
  hook: WhoopYourWayComponent;
  slider: WhoopYourWayComponent;
}

export interface WhoopYourWaySelection {
  band: WhoopYourWayOption;
  clasp: WhoopYourWayOption;
  hook: WhoopYourWayOption;
  slider: WhoopYourWayOption;
}

export interface Media {
  id: number;
  media_type: string;
  url_path: string;
  filename: string;
}
export interface WhoopYourWayBase {
  front_media: Media;
  back_media: Media;
  title: string;
  currency: string;
}
export interface WhoopYourWayComponent {
  options: WhoopYourWayOption[];
  title: string;
}
export interface WhoopYourWayOption {
  sku: string;
  color_group: string;
  color_text: string;
  code: string;
  price: number;
  quantity: number;
  swatch: Media;
  front_media: Media;
  back_media: Media;
  // optional, not all components have band types
  band_type_group?: string;
  band_type_text?: string;
}

export interface Collection {
  handle: string;
  title: string;
  description: string;
  products: string[];
}

export async function fetchWywProducts(): Promise<WhoopYourWayConfigurations> {
  return cachedFetch(
    `${API_PROTOCOL}://${API_DOMAIN}/product-service/v1/wyw/config/currency/${SHOP_CURRENCY_CODE}?language=${process.env.SHOP_LANGUAGE}`,
  );
}

export async function fetchProduct(handle: string): Promise<ProductNode> {
  return cachedFetch(
    `${API_PROTOCOL}://${API_DOMAIN}/product-service/v2/product/${handle}?language=${process.env.SHOP_LANGUAGE}&channel=storefront&currency=${SHOP_CURRENCY_CODE}`,
  );
}

export async function fetchPage(handle: string): Promise<WhoopPage> {
  return cachedFetch(
    `${API_PROTOCOL}://${API_DOMAIN}/product-service/v1/page/${handle}?language=${process.env.SHOP_LANGUAGE}`,
  );
}

export async function fetchCollection(handle: string): Promise<Collection> {
  return cachedFetch(
    `${API_PROTOCOL}://${API_DOMAIN}/product-service/v1/collection/${handle}`,
  );
}

export const useProductServiceCollection = (
  handle?: string,
): Optional<Collection> => {
  const [collectionCache, setCollectionCache] = useState<{
    [sku: string]: Collection;
  }>({});
  useEffect(() => {
    if (handle && collectionCache[handle] === undefined) {
      fetchCollection(handle).then((collection: Collection) => {
        setCollectionCache({
          ...collectionCache,
          [handle]: collection, // append collection to cache
        });
      });
    }
  }, [handle]); // for every new shopifyId make a new call
  if (handle) {
    return collectionCache[handle];
  }
};

/**
 * Fetches and Caches a given product page
 * @param handle page handle
 */
export const useProductServicePage = (handle?: string): Optional<WhoopPage> => {
  const [pageCache, setPageCache] = useState<{ [sku: string]: WhoopPage }>({});
  useEffect(() => {
    if (handle && pageCache[handle] === undefined) {
      fetchPage(handle).then((page: WhoopPage) => {
        setPageCache({
          ...pageCache,
          [handle]: page, // append new page to cache
        });
      });
    }
  }, [handle]); // for every new shopifyId make a new call
  if (handle) {
    return pageCache[handle];
  }
};

/**
 * Fetches and caches a list product nodes
 * @param handles node handle
 */
export function useProductServiceNodes(
  handles: string[],
): Optional<ProductNode>[] {
  const [nodeCache, setNodeCache] = useState<{ [handle: string]: ProductNode }>(
    {},
  );
  useEffect(() => {
    handles
      ?.filter((handle) => !nodeCache[handle]) // filter if already in the cache
      ?.forEach((handle) => {
        fetchProduct(handle).then((node: ProductNode) => {
          setNodeCache({
            ...nodeCache,
            [handle]: node, // append node to cache
          });
        });
      });
  }, [handles]);

  return handles.map((handle) => nodeCache[handle]);
}

/**
 * Fetches and caches a given product node
 * @param handle node handle
 */
export const useProductServiceNode = singuralizeHook(useProductServiceNodes);
